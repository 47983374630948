import { useState, useEffect } from 'react';
import * as prismic from '@prismicio/client';
import { prismicConfig } from '../../config';
import tmpl from './Intro.jsx';

function Intro() {
  const [doc, setDoc] = useState(null);

  useEffect(() => {
    async function fetchIntro() {
      const prismicClient = prismic.createClient(prismicConfig.apiEndpoint, {
        accessToken: prismicConfig.accessToken,
      });
      const document = await prismicClient.getSingle('intro');
      if (document) {
        setDoc(document);
      } else {
        // @todo: Handle exception if no content found for doc type 'intro'
        console.log(`No content found for document type "intro"`);
      }
    }
    fetchIntro();
  }, []);

  return tmpl(doc);
}

export default Intro;
