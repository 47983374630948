import tmpl from './Quote.jsx';
import PropTypes from 'prop-types';

const Quote = props => tmpl(props);

Quote.propTypes = {
  id: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  text: PropTypes.array.isRequired,
  author_name: PropTypes.array.isRequired,
  author_title: PropTypes.array,
  author_portrait: PropTypes.object.isRequired,
  display: PropTypes.string,
};

export default Quote;
