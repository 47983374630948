import PropTypes from 'prop-types';
import tmpl from './BlogPostTeaser.jsx';

const BlogPostTeaser = props => tmpl(props);

BlogPostTeaser.propTypes = {
  id: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  title: PropTypes.array.isRequired,
  teaser: PropTypes.array,
  key_image: PropTypes.object,
  display: PropTypes.string,
};

export default BlogPostTeaser;
