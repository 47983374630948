import Moment from 'moment';
import 'moment/locale/de-ch';
import prismic from './config/prismic.config';

// Localize Moment.js
Moment.locale('de');

// Global config obj
const config = {
  // Pass-through Prismic configuration
  prismic: {
    apiEndpoint: prismic.apiEndpoint,
    accessToken: prismic.accessToken,
    linkResolver: prismic.linkResolver,
    htmlSerializer: prismic.htmlSerializer,
  },
  mailchimp: {
    formUrl:
      'https://abz.us15.list-manage.com/subscribe/post?u=c042328aab6bd0cf1f9951400&amp;id=8b8b505f1e',
  },
};

export const prismicConfig = config.prismic;
export const mailchimpConfig = config.mailchimp;
