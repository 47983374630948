import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config';
import { stripProtocol } from '../../helpers';
import './RessourceLink.css';

const tmpl = ({ item }) => {
  const title = RichText.asText(item.link_title).trim() || null;
  if (item.link?.link_type === 'Web') {
    return (
      <a href={item.link.url} target="_blank">
        {title || stripProtocol(item.link.url)}
      </a>
    );
  }
  if (item.link?.link_type === 'Media') {
    return (
      <a href={item.link.url} target="_blank">
        {title || item.link.name}
      </a>
    );
  }
  if (item.media?.link_type === 'Media') {
    return (
      <a href={item.media.url} target="_blank">
        {title || item.media.name}
      </a>
    );
  }
  return (
    <Link to={prismicConfig.linkResolver(item.link)}>
      {title || item.link.uid || 'Home'}
    </Link>
  );
};

export default tmpl;
