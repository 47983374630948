import PropTypes from 'prop-types';
import tmpl from './EventTeaser.jsx';

const EventTeaser = props => tmpl(props);

EventTeaser.propTypes = {
  id: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  label: PropTypes.array,
  title: PropTypes.array.isRequired,
  teaser: PropTypes.array,
  key_image: PropTypes.object,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  display: PropTypes.string,
};

export default EventTeaser;
