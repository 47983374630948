import React from 'react';
import { applyTenantTheme } from '../../helpers.js';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config.js';
import Placeholder from '../../components/Placeholder/Placeholder';
import NewsletterForm from '../../components/NewsletterForm/NewsletterForm';
import './Intro.css';

applyTenantTheme('layout/Intro/Intro');

const tmpl = doc => {
  if (doc) {
    const { title, text } = doc.data;
    return (
      <div className="kq-intro">
        <span className="kq-landing__section-title">Intro</span>
        <h1 className="kq-intro__title">
          {title ? RichText.asText(title) : null}
        </h1>
        <div className="kq-intro__richtext">
          {text ? RichText.render(text, prismicConfig.linkResolver) : null}
        </div>
        <NewsletterForm />
      </div>
    );
  }
  return (
    <Placeholder
      title={{ lines: 2, size: 'xl' }}
      teaser={{ lines: 6, size: 'm' }}
    />
  );
};

export default tmpl;
