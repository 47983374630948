import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as prismic from '@prismicio/client';
import { prismicConfig } from '../../config';
import tmpl from './Nav.jsx';

function Nav({ hideNav, isNavActive, className }) {
  const [doc, setDoc] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const prismicClient = prismic.createClient(prismicConfig.apiEndpoint, {
        accessToken: prismicConfig.accessToken,
      });
      const document = await prismicClient.getSingle('navigation');
      if (document) {
        setDoc(document);
      } else {
        // @todo: Redirect to NotFound if no content available
        console.log(`No content found for type 'navigation'`);
      }
    }
    fetchData();
  }, []);

  return tmpl(hideNav, isNavActive, doc, className);
}

Nav.propTypes = {
  hideNav: PropTypes.func.isRequired,
  isNavActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Nav;
