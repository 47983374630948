import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config.js';
import Moment from 'moment';
import ResponsiveImage from '../../components/ResponsiveImage/ResponsiveImage';
import './EventTeaser.css';

const tmpl = ({
  uid,
  title,
  teaser,
  label,
  key_image,
  start_time,
  end_time,
  location,
  display,
}) => {
  const hasLabel = RichText.asText(label).trim().length > 0;
  return (
    <article
      className={`kq-collection__item kq-collection__item--event ${
        display === 'Highlighted' ? 'kq-collection__item--highlighted' : ''
      } ${hasLabel ? 'kq-collection__item--with-label' : ''}`}
    >
      <Link className="kq-collection__item-link" to={`/event/${uid}`}>
        <span
          className={`kq-landing__section-title ${
            display === 'Highlighted'
              ? 'kq-landing__section-title--highlighted'
              : null
          }`}
        >
          Veranstaltung
        </span>
        {hasLabel ? (
          <span className="kq-collection__label">{RichText.asText(label)}</span>
        ) : null}
        <ResponsiveImage
          className="kq-collection__key-image"
          image={key_image}
        />
        <h1 className="kq-collection__item-title">
          {title ? RichText.asText(title) : null}
        </h1>
        <div className="kq-collection__richtext">
          {teaser ? RichText.render(teaser, prismicConfig.linkResolver) : null}
        </div>
        <div className="kq-collection__meta">
          {start_time ? (
            <time className="kq-collection__time" dateTime={start_time}>
              {Moment(start_time).format('Do MMMM YYYY, H:mm')}
              {end_time ? ` – ${Moment(end_time).format('H:mm')}` : null}
            </time>
          ) : null}
          {location ? (
            <div className="kq-collection__location">
              {RichText.render(location, prismicConfig.linkResolver)}
            </div>
          ) : null}
        </div>
      </Link>
    </article>
  );
};

export default tmpl;
