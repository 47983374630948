import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import CacheBuster from 'react-cache-buster';
import pkg from '../../package.json';
import BlogPost from '../pages/BlogPost/BlogPost';
import Event from '../pages/Event/Event';
import Header from '../layout/Header/Header';
import Footer from '../layout/Footer/Footer';
import Landing from '../pages/Landing/Landing';
import Page from '../pages/Page/Page';
import Newsletter from '../pages/Newsletter/Newsletter';
import NotFound from '../pages/NotFound/NotFound';
import Preview from '../pages/Preview/Preview';
import { Helmet } from 'react-helmet';
import { raenv } from '../helpers';

class App extends React.Component {
  render() {
    const host = window.location.host;
    // Output version to console
    console.log(`${pkg.name}@${pkg.version}`);
    const isProduction = process.env.NODE_ENV === 'production';
    return (
      <CacheBuster
        currentVersion={pkg?.version}
        isEnabled={isProduction}
        isVerboseMode={false}
      >
        <Router>
          <Helmet>
            <title>{raenv('TITLE')}</title>
            // Prevent staging from being indexed
            {host.startsWith(raenv('STAGING_HOST')) && (
              <meta name="robots" content="noindex, nofollow" />
            )}
          </Helmet>
          <div className="kq-wrapper">
            <Header />
            <div className="kq-content">
              <Routes>
                <Route exact path="/" element={<Landing />} />
                <Route exact path="/blog/:uid" element={<BlogPost />} />
                <Route exact path="/event/:uid" element={<Event />} />
                <Route exact path="/page/:uid" element={<Page />} />
                <Route exact path="/newsletter" element={<Newsletter />} />
                <Route exact path="/preview" element={<Preview />} />
                <Route element={<NotFound />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </CacheBuster>
    );
  }
}

export default App;
