import 'babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { applyTenantTheme } from './helpers';
import './styles/main.css';
import App from './App/App';
import registerServiceWorker from './registerServiceWorker';

applyTenantTheme('styles/main');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
registerServiceWorker();
