import React from 'react';
import { applyTenantTheme } from '../../helpers';
import { Link } from 'react-router-dom';
import './NotFound.css';

applyTenantTheme('pages/NotFound/NotFound');

const tmpl = () => (
  <div className="kq-stripe">
    <div className="kq-stripe__layout">
      <div className="kq-stripe__inner">
        <h1 className="kq-not-found__title">
          Oops… diese Seite gibt es nicht.
        </h1>
        <div className="kq-not-found__richtext">
          <p>
            <Link to="/">Hier geht es zur Startseite.</Link>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default tmpl;
