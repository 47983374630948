import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config.js';
import ResponsiveImage from '../../components/ResponsiveImage/ResponsiveImage';
import './BlogPostTeaser.css';

const tmpl = ({ uid, title, teaser, key_image, display }) => {
  return (
    <article
      className={`kq-collection__item kq-collection__item--blog-post ${
        display === 'Highlighted' ? 'kq-collection__item--highlighted' : ''
      }`}
    >
      <Link className="kq-collection__item-link" to={`/blog/${uid}`}>
        <span
          className={`kq-landing__section-title ${
            display === 'Highlighted'
              ? 'kq-landing__section-title--highlighted'
              : null
          }`}
        >
          Blog
        </span>
        <ResponsiveImage
          className="kq-collection__key-image"
          image={key_image}
        />
        <h1 className="kq-collection__item-title">
          {title ? RichText.asText(title) : null}
        </h1>
        <div className="kq-collection__richtext">
          {teaser ? RichText.render(teaser, prismicConfig.linkResolver) : null}
        </div>
      </Link>
    </article>
  );
};

export default tmpl;
