import React from 'react';
import { raenv, applyTenantTheme } from '../../helpers';
import { Link } from 'react-router-dom';
import Nav from '../Nav/Nav'
import './Header.css';
import ScrollDistObserver from '@pixelherz/reactbox/scroll-distance-observer';
import classNames from 'classnames';

applyTenantTheme('layout/Header/Header');

const tmpl = (isNavActive, toggleNav, hideNav, setReduced, isReduced) => {
  const headerClass = classNames('kq-header', { ['kq-header--reduced']: isReduced })
  const navClass = classNames('kq-header__nav', { ['kq-header__nav--reduced']: isReduced })
  return (
    <>
      <ScrollDistObserver
        onScrollDistYUpdate={(scrollDistY) => {
          setReduced(scrollDistY > 50);
        }}
      />
      <div className={headerClass}>
        <h1 className="kq-header__logo">
          <Link to="/" className="kq-header__home-link" onClick={hideNav}>
            {raenv('NAME')}
          </Link>
        </h1>
        <button
          className={`kq-header__burger ${
            isNavActive ? 'kq-header__burger--active' : ''
          }`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
          <span />
        </button>
      </div>
      <Nav
        className={navClass}
        isNavActive={isNavActive}
        hideNav={hideNav}
      />
    </>
  )
};

export default tmpl;
