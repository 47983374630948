import classNames from 'classnames';
import Carousel from 'nuka-carousel/lib/carousel';
import KeyVisual from '../KeyVisual/KeyVisual';
import './Carousel.css';

const DIRECTION = { previous: 'previous', next: 'next' };

/**
 * Main template
 * Renders multiple images with optional captions in a carousel component.
 */
const tmpl = ({ slides }) => {
  // Filter-out slides without image
  slides = slides.filter((slide) => slide.slide_image?.url);
  // Do not render Carousel component if no slides
  if (slides.length < 1) return null;
  // Render Carousel
  return (
    <>
      <Carousel
        autoplay
        wrapAround
        renderBottomCenterControls={false}
        renderCenterLeftControls={({ previousSlide, previousDisabled }) => (
          <StepperBtn
            label="zurück"
            direction={DIRECTION.previous}
            click={previousSlide}
            isDisabled={previousDisabled}
          />
        )}
        renderCenterRightControls={({ nextSlide, nextDisabled }) => (
          <StepperBtn
            label="weiter"
            direction={DIRECTION.next}
            click={nextSlide}
            isDisabled={nextDisabled}
          />
        )}
      >
        {slides?.map((slide, index) => {
          return (
            <KeyVisual
              key={index}
              image={slide.slide_image}
              caption={slide.slide_caption}
            />
          );
        })}
      </Carousel>
    </>
  );
};

/**
 * Button prev/next
 */
const StepperBtn = ({ label, direction, click, isDisabled }) => (
  <button
    onClick={click}
    disabled={isDisabled}
    className={classNames(
      'kq-carousel__stepper',
      `kq-carousel__stepper--${direction}`
    )}
  >
    {label}
  </button>
);

export default tmpl;
