import React from 'react';
import { Elements } from 'prismic-reactjs';
import * as prismic from '@prismicio/client';

const apiEndpoint = prismic.getRepositoryEndpoint(
  process.env.REACT_APP_PRISMIC_REPOSITORY_NAME
);
// As the access token is included in the bundle anyway, we can include it in
// the code base (env config).
const accessToken = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;

const linkResolver = (doc) => {
  // Define the url depending on the document type
  if (doc.type === 'page') {
    return '/page/' + doc.uid;
  } else if (doc.type === 'blog_post') {
    return '/blog/' + doc.uid;
  } else if (doc.type === 'event') {
    return '/event/' + doc.uid;
  }
  // Default to homepage
  return '/';
};

// Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

const htmlSerializer = (type, element, content, children, key) => {
  if (type === Elements.embed) {
    element.oembed.html = element.oembed.html.replace(
      /width="[0-9]+"/i,
      'width="100%"'
    );
    element.oembed.html = element.oembed.html.replace(
      /height="[0-9]+"/i,
      'height="100%"'
    );
    const props = Object.assign(
      {
        'data-oembed': element.oembed.embed_url,
        'data-oembed-type': element.oembed.type,
        'data-oembed-provider': element.oembed.provider_name,
      },
      element.label ? { className: element.label } : {}
    );
    const embedHtml = React.createElement('div', {
      dangerouslySetInnerHTML: { __html: element.oembed.html },
    });
    return React.createElement(
      'div',
      propsWithUniqueKey(props, key),
      embedHtml
    );
  } else {
    return null;
  }
};

export default { apiEndpoint, accessToken, linkResolver, htmlSerializer };
