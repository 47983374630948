/**
 * Remove protocol from URL
 * @param {String} url
 */
const stripProtocol = (url = '') => {
  const index = url.indexOf('://');
  if (index < 0) {
    return url;
  }
  return url.substring(index + 3);
};

/**
 * Shortcut for getting environment variables with prefix `REACT_APP_`.
 */
const raenv = (name = '') => process.env[`REACT_APP_${name}`];

/**
 * Load environment environment specific stylesheet (theme)
 */
const applyTenantTheme = (comp = '') => {
  try {
    require(`./${comp}.${raenv('TENANT')}.css`);
  } catch (err) {
    process.env.NODE_ENV === 'development' &&
      console.warn(
        `Warning: Could not load theme ./${comp}.${raenv('TENANT')}.css.`
      );
  }
};

export { raenv, applyTenantTheme, stripProtocol };
