import React from 'react';
import NewsletterForm from '../../components/NewsletterForm/NewsletterForm';
import './Newsletter.css';

const tmpl = () => {
  return (
    <div className="kq-stripe">
      <div className="kq-stripe__layout">
        <div className="kq-stripe__inner">
          <h1 className="kq-intro__title">Newsletter</h1>
          <NewsletterForm />
        </div>
      </div>
    </div>
  );
};

export default tmpl;
