import tmpl from './Loader.jsx';
import PropTypes from 'prop-types';

const Loader = props => tmpl(props);

Loader.proptypes = {
  minHeight: PropTypes.string,
};

export default Loader;
