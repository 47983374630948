import { useState, useEffect } from 'react';
import * as prismic from '@prismicio/client';
import { prismicConfig } from '../../config';
import tmpl from './Footer.jsx';

function Footer() {
  const [doc, setDoc] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const prismicClient = prismic.createClient(prismicConfig.apiEndpoint, {
        accessToken: prismicConfig.accessToken,
      });
      const document = await prismicClient.getSingle('footer');
      if (document) {
        setDoc(document);
      } else {
        // @todo: Redirect to NotFound if no content available
        console.log(`No content found for type 'footer'`);
      }
    }
    fetchData();
  }, []);

  return tmpl(doc);
}

export default Footer;
