import React from 'react';
import { applyTenantTheme } from '../../helpers';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import './NewsletterForm.css';

applyTenantTheme('components/NewsletterForm/NewsletterForm');

const tmpl = url => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => {
      return (
        <div>
          <SubscribeForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
          {status
            ? messageTmpl(
                status === 'sending' ? 'Einen Moment bitte…' : message,
                status
              )
            : null}
        </div>
      );
    }}
  />
);

const messageTmpl = (message, status) => (
  <div
    className={`kq-newsletter-form__message ${
      status === 'error' ? 'kq-newsletter-form__message--error' : ''
    }`}
    dangerouslySetInnerHTML={{ __html: message }}
  />
);

const SubscribeForm = ({ status, message, onValidated }) => {
  let email;
  const submit = event => {
    event.preventDefault();
    email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  };

  return (
    <form onSubmit={submit}>
      <div className="kq-newsletter-form__group">
        <input
          type="email"
          ref={node => (email = node)}
          required
          defaultValue={email}
          className="kq-newsletter-form__input kq-newsletter-form__input--email"
          placeholder="lotte@home.ch"
        />
        <input
          type="submit"
          className="kq-newsletter-form__input kq-newsletter-form__input--submit"
          value="Anmelden"
        />
      </div>
    </form>
  );
};

export default tmpl;
