import React from 'react';
import { applyTenantTheme } from '../../helpers';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import Moment from 'moment';
import { prismicConfig } from '../../config';
import Placeholder from '../../components/Placeholder/Placeholder';
import KeyVisual from '../../components/KeyVisual/KeyVisual';
import RessourceGroup from '../../components/RessourceGroup/RessourceGroup';
import './Event.css';

applyTenantTheme('pages/Event/Event');

/**
 * Main template
 */
const tmpl = (doc) => (
  <div className="kq-stripe">
    <div className="kq-stripe__layout">
      <div className="kq-stripe__inner">
        {doc ? (
          article(doc.data)
        ) : (
          <Placeholder
            title={{ lines: 2, size: 'xl' }}
            teaser={{ lines: 5, size: 'l' }}
          />
        )}
      </div>
    </div>
  </div>
);

/**
 * Article template
 */
const article = ({
  title,
  teaser,
  text,
  key_image,
  key_image_credits,
  ressources,
  start_time,
  end_time,
  location,
}) => (
  <article className="kq-event">
    <Link to="/" className="kq-event__back">
      <button className="kq-button-back">Zur Übersicht</button>
    </Link>
    <KeyVisual image={key_image} caption={key_image_credits} />
    <h1 className="kq-event__title">{title ? RichText.asText(title) : null}</h1>
    <div className="kq-event__teaser">
      {teaser ? RichText.render(teaser, prismicConfig.linkResolver) : null}
    </div>
    <div className="kq-event__meta">
      {start_time ? (
        <time className="kq-event__time" dateTime={start_time}>
          {Moment(start_time).format('Do MMMM YYYY, H:mm')}
          {end_time ? ` – ${Moment(end_time).format('H:mm')}` : null}
        </time>
      ) : null}
      {location ? (
        <div className="kq-event__location">
          {RichText.render(location, prismicConfig.linkResolver)}
        </div>
      ) : null}
    </div>
    <div className="kq-event__richtext">
      {text ? RichText.render(text, prismicConfig.linkResolver) : null}
    </div>
    <div className="kq-event__ressources">
      {ressources.map((group, index) => (
        <RessourceGroup group={group} index={index} key={index} />
      ))}
    </div>
  </article>
);

export default tmpl;
