import { useState } from 'react';
import tmpl from './Header.jsx';

function Header() {
  const [isNavActive, setIsNavActive] = useState(false);
  const [isReduced, setIsReduced] = useState(false);

  const toggleNav = () => {
    setIsNavActive((isNavActive) => !isNavActive);
  };

  const hideNav = () => {
    isNavActive && toggleNav();
  };

  const setReduced = (isReduced) => {
    setIsReduced(isReduced);
  };

  return tmpl(isNavActive, toggleNav, hideNav, setReduced, isReduced);
}

export default Header;
