import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as prismic from '@prismicio/client';
import { prismicConfig } from '../../config';
import withPreview from '../../hoc/WithPreview/WithPreview';
import tmpl from './Page.jsx';

const Page = () => {
  const { uid } = useParams();
  const [doc, setDoc] = useState(null);
  const client = prismic.createClient(prismicConfig.apiEndpoint, {
    accessToken: prismicConfig.accessToken,
  });

  /**
   * Update when uid changes
   */
  useEffect(() => {
    fetchContent(uid);
  }, [uid]);

  /**
   * Fetch content from CMS
   */
  const fetchContent = async (uid) => {
    const document = await client.getByUID('page', uid);
    // @TODO: Redirect to NotFound if no content available (uid is invalid)
    document
      ? setDoc(document)
      : console.log(
          `${this.constructor.name}: No content found for pageId '${uid}'`
        );
  };

  return tmpl(doc);
};

export default withPreview(Page);
