import React from 'react';
import { applyTenantTheme } from '../../helpers';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config';
import Placeholder from '../../components/Placeholder/Placeholder';
import RessourceGroup from '../../components/RessourceGroup/RessourceGroup';
import IFrame from '../../components/IFrame/IFrame';
import KeyVisual from '../../components/KeyVisual/KeyVisual';
import Carousel from '../../components/Carousel/Carousel';
import './BlogPost.css';

applyTenantTheme('pages/BlogPost/BlogPost');

/**
 * Main template
 */
const tmpl = (doc) => {
  return (
    <div className="kq-stripe">
      <div className="kq-stripe__layout">
        <div className="kq-stripe__inner">
          {doc ? (
            article(doc.data)
          ) : (
            <Placeholder
              title={{ lines: 2, size: 'xl' }}
              teaser={{ lines: 5, size: 'l' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Article template
 */
const article = ({
  title,
  teaser,
  text,
  key_image,
  key_image_credits,
  slides,
  ressources,
}) => {
  // Filter for slices rendered via component `RessourceGroup`
  const ressourceGroupItems = ressources.filter(
    (item) => item.slice_type === 'link' || item.slice_type === 'media'
  );
  // Filter form slices rendered via component `IFrame`
  const iFrameItems = ressources.filter((item) => item.slice_type === 'iframe');

  // Return template
  return (
    <article className="kq-blog-post">
      <Link to="/" className="kq-blog-post__back">
        <button className="kq-button-back">Zur Übersicht</button>
      </Link>
      {slides?.length > 0 ? (
        <Carousel slides={slides} />
      ) : (
        <KeyVisual image={key_image} caption={key_image_credits} />
      )}
      <h1 className="kq-blog-post__title">
        {title ? RichText.asText(title) : null}
      </h1>
      <div className="kq-blog-post__teaser">
        {teaser ? RichText.render(teaser, prismicConfig.linkResolver) : null}
      </div>
      {iFrameItems.length > 0 &&
        iFrameItems.map((item, index) => <IFrame {...item} key={index} />)}
      <div className="kq-blog-post__richtext">
        {text
          ? RichText.render(
              text,
              prismicConfig.linkResolver,
              prismicConfig.htmlSerializer
            )
          : null}
      </div>
      <div className="kq-blog-post__ressources">
        {ressourceGroupItems.map((group, index) => (
          <RessourceGroup group={group} index={index} key={index} />
        ))}
      </div>
    </article>
  );
};

export default tmpl;
