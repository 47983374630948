import { useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import './IFrame.css';

const tmpl = ({ source, height, responsiveHeights }) => {
  const [frameHeight, setFrameHeight] = useState(height);
  const wrapperRef = useRef(null);

  // Sort viewport widths ascending
  responsiveHeights.sort((a, b) => a['frame_min-width'] < b['frame_min-width']);

  // Match responsiveHeights based on frame width
  const matchFrameHeight = (frameWidth) => {
    const match = responsiveHeights.find((query) => {
      return frameWidth >= query['frame_min-width'];
    });
    setFrameHeight(match ? match['height_responsive'] : height);
  };

  // Trigger matching when frame resizes
  useResizeObserver(wrapperRef, (entry) => {
    matchFrameHeight(entry.contentRect.width);
  });

  return (
    <>
      <div
        ref={wrapperRef}
        className="kq-iframe__outer"
        style={{ paddingBottom: frameHeight }}
      >
        <div className="kq-iframe__inner">
          <iframe
            className="kq-iframe__content"
            src={source}
            frameBorder="0"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default tmpl;
