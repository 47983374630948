import React from 'react';
import pkg from '../../../package.json';
import './Footer.css';

const links = [
  { name: 'abz', url: 'https://abz.ch' },
  { name: 'kraftwerk1', url: 'http://www.kraftwerk1.ch' },
  { name: 'senn', url: 'https://senn.com' },
  { name: 'gsz', url: 'https://www.stadt-zuerich.ch/ted/de/index/gsz/aktuell/aktuelle-projekte/quartierpark-kochareal.html' },
];

const tmpl = (doc) => {
  return (
    <div className="kq-footer kq-stripe">
      <div className="kq-stripe__layout">
        <div className="kq-stripe__inner">
        <span className="kq-footer__version">v{pkg.version}</span>
          <div className="kq-footer__logos">
            {doc?.data.supporters.map(supporter => (
              <a
                key={supporter.name}
                className="kq-footer__link"
                href={supporter.website}
                target="_blank"
                rel="noreferrer noopener"
              >
                <div
                  className="kq-footer__logo"
                  style={{
                    'backgroundImage': `url(${supporter.logotype.url})`,
                    'height': `${supporter.logotype_scale}%`,
                  }}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default tmpl;
