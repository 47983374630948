import React from 'react';
import { applyTenantTheme } from '../../helpers';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config.js';
import './Quote.css';

applyTenantTheme('layout/Quote/Quote');

const tmpl = ({
  uid,
  text,
  author_name,
  author_title,
  author_portrait,
  display,
}) => {
  return (
    <blockquote
      className={`kq-quote kq-collection__item kq-collection__item--quote ${
        display === 'Highlighted' ? 'kq-collection__item--highlighted' : ''
      }`}
    >
      <div
        className="kq-quote__author-portrait"
        style={{ backgroundImage: `url(${author_portrait.url})` }}
      />
      <p className="kq-quote__text">
        {text ? RichText.asText(text, prismicConfig.linkResolver).trim() : null}
      </p>
      <p className="kq-quote__author">
        <span className="kq-quote__author-name">
          {author_name
            ? RichText.asText(author_name, prismicConfig.linkResolver)
            : null}
          {author_title ? ', ' : ''}
        </span>
        <span className="kq-quote__author-title">
          {author_title
            ? RichText.asText(author_title, prismicConfig.linkResolver)
            : null}
        </span>
      </p>
    </blockquote>
  );
};

export default tmpl;
