import ResponsiveImage from '../../components/ResponsiveImage/ResponsiveImage';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config';
import './KeyVisual.css';

/**
 * Main template
 * Renders a single image with an optional caption.
 */
const tmpl = ({ image, caption }) => {
  return (
    <>
      <ResponsiveImage className="kq-key_visual" image={image} />
      {caption && (
        <div className="kq-key_visual__caption">
          {RichText.render(caption, prismicConfig.linkResolver)}
        </div>
      )}
    </>
  );
};

export default tmpl;
