import React from 'react';
import { applyTenantTheme } from '../../helpers';
import RessourceLink from '../../components/RessourceLink/RessourceLink';
import './RessourceGroup.css';

applyTenantTheme('components/RessourceGroup/RessourceGroup');

const tmpl = ({ group, index }) => {
  return (
    <ul className="kq-ressource-group" key={index}>
      {group.items.map((item, i) => {
        return (
          <li className="kq-ressource-group__ressource" key={i}>
            <RessourceLink item={item} />
          </li>
        );
      })}
    </ul>
  );
};

export default tmpl;
