import React from 'react';
import { applyTenantTheme } from '../../helpers';
import Intro from '../../layout/Intro/Intro';
import Collection from '../../layout/Collection/Collection';
import './Landing.css';

applyTenantTheme('pages/Landing/Landing');

const tmpl = () => {
  return (
    <div className="kq-landing kq-stripe">
      <div className="kq-stripe__layout">
        <div className="kq-stripe__inner">
          <Intro />
          <Collection />
        </div>
      </div>
    </div>
  );
};

export default tmpl;
