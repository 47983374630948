import React from 'react';
import { applyTenantTheme } from '../../helpers';
import { RichText } from 'prismic-reactjs';
import { prismicConfig } from '../../config.js';
import Placeholder from '../../components/Placeholder/Placeholder';
import Carousel from '../../components/Carousel/Carousel';
import KeyVisual from '../../components/KeyVisual/KeyVisual';
import RessourceGroup from '../../components/RessourceGroup/RessourceGroup';
import IFrame from '../../components/IFrame/IFrame';
import './Page.css';

applyTenantTheme('pages/Page/Page');

const tmpl = (doc) => {
  return (
    <div className="kq-stripe">
      <div className="kq-stripe__layout">
        <div className="kq-stripe__inner">
          {doc ? (
            article(doc.data)
          ) : (
            <Placeholder
              title={{ lines: 2, size: 'xl' }}
              teaser={{ lines: 5, size: 'm' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const article = ({ title, text, key_image, slides, ressources }) => {
  // Filter for slices rendered via component `RessourceGroup`
  const ressourceGroupItems = ressources.filter(
    (item) => item.slice_type === 'link' || item.slice_type === 'media'
  );
  // Filter form slices rendered via component `IFrame`
  const iFrameItems = ressources.filter((item) => item.slice_type === 'iframe');

  return (
    <article className="kq-page">
      {slides?.length > 0 ? (
        <Carousel slides={slides} />
      ) : (
        <KeyVisual image={key_image} />
      )}
      <h1 className="kq-page__title">
        {title ? RichText.asText(title) : null}
      </h1>
      {iFrameItems.length > 0 &&
        iFrameItems.map((item, index) => <IFrame {...item} key={index} />)}
      <div className="kq-page__richtext">
        {text ? RichText.render(text, prismicConfig.linkResolver) : null}
      </div>
      <div className="kq-page__ressources">
        {ressourceGroupItems.map((group, index) => (
          <RessourceGroup group={group} index={index} key={index} />
        ))}
      </div>
    </article>
  );
};

export default tmpl;
