import React from 'react';
import * as prismic from '@prismicio/client';
import { prismicConfig } from '../../config';

function withPreview(WrappedComponent) {
  return class extends React.Component {
    state = {
      api: null,
    };

    componentDidMount() {
      const prismicClient = prismic.createClient(prismicConfig.apiEndpoint, {
        accessToken: prismicConfig.accessToken,
      });
      this.setState({ api: prismicClient });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withPreview;
