import React from 'react';
import * as prismic from '@prismicio/client';
import { prismicConfig } from '../../config';
import tmpl from './Collection.jsx';

class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { docs: [], hasMorePosts: true };
    this.fetchContent = this.fetchContent.bind(this);
  }

  // State should not be updated until pinned posts AND regular posts are
  // loaded. tmpDocs keeps track of all docs loaded.
  // tmpDocs[0] --> pinned
  // tmpDocs[1] --> page 1
  // tmpDocs[2] --> page 2
  tmpDocs = [];

  predicates = [
    prismic.filter.any('document.type', ['blog_post', 'event', 'quote']),
    prismic.filter.not('my.blog_post.display', 'Hidden'),
    prismic.filter.not('my.event.display', 'Hidden'),
    prismic.filter.not('my.quote.display', 'Hidden'),
  ];

  // Fields to be fetched when requesting content
  fetchFields = [
    'blog_post.title',
    'blog_post.teaser',
    'blog_post.key_image',
    'blog_post.display',
    'blog_post.position',
    'event.title',
    'event.teaser',
    'event.label',
    'event.key_image',
    'event.start_time',
    'event.end_time',
    'event.location',
    'event.display',
    'event.position',
    'quote.text',
    'quote.author_name',
    'quote.author_title',
    'quote.author_portrait',
    'quote.display',
    'quote.position',
  ];

  // Content ordering for queries
  orderings = [
    {
      field: 'document.first_publication_date',
      direction: 'desc',
    },
  ];

  /**
   * Init: Load content from backend
   */
  componentDidMount() {
    this.fetchPinned();
  }

  /**
   * Fetch pinned blog posts, events and quotes from CMS
   */
  async fetchPinned() {
    const prismicClient = prismic.createClient(prismicConfig.apiEndpoint, {
      accessToken: prismicConfig.accessToken,
    });
    const response = await prismicClient.get({
      predicates: this.predicates,
      fetch: this.fetchFields,
      orderings: this.orderings,
    });
    if (response) {
      this.tmpDocs[0] = response.results.filter(
        (doc) => doc.data.position === 'Pinned'
      );
      this.setState({ docs: this.tmpDocs.flat() });
    } else {
      // @todo: Handle exception if no content found
      console.log(`${this.constructor.name}: No content found`);
    }
  }

  /**
   * Fetch not-pinned blog posts, events and quotes from CMS
   */
  async fetchContent(pageNum) {
    const prismicClient = prismic.createClient(prismicConfig.apiEndpoint, {
      accessToken: prismicConfig.accessToken,
    });
    const response = await prismicClient.get({
      predicates: this.predicates,
      pageSize: 10,
      page: pageNum,
      fetch: this.fetchFields,
      orderings: this.orderings,
    });
    if (response) {
      this.tmpDocs[pageNum] = response.results.filter(
        (doc) => doc.data.position === 'Default'
      );
      // Do not set state while we did not receive filtered docs (flickering)
      if (this.tmpDocs[0]) {
        this.setState({
          docs: this.tmpDocs.flat(),
          hasMorePosts: response.page < response.total_pages,
        });
      }
    } else {
      // @todo: Handle exception if no content found
      console.log(`${this.constructor.name}: No content found`);
    }
  }

  render = () =>
    tmpl(this.state.docs, this.fetchContent, this.state.hasMorePosts);
}

export default Collection;
